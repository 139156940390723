<template>
  <div class="vendor-item">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/gift-vendors">Product Vendors</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span @click="number ? backDetail() : ''" :class="number ? 'disabled--text link' : 'black--text'">{{ data.title_en }}</span>
      <span v-if="number">
        <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
        <span @click="backBooking" class="disabled--text link text-capitalize">{{ $route.params.tab }}</span>
        <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon><span>{{ number }}</span>
      </span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
          <div
            v-if="role == 'admin' || role == 'manager' || role == 'product_vendor'"
            :class="link == 'orders' ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu('orders')"
            class="item-menu_item link"
          >
            <div>Orders</div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'general'">
          <General />
        </div>
        <div class="width100" v-if="link == 'delivery'">
          <Delivery />
        </div>
        <div class="width100" v-if="link == 'products'">
          <Products />
        </div>
        <div class="width100" v-if="link == 'orders' && (role == 'admin' || role == 'manager' || role == 'product_vendor')">
          <Orders />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import General from '@/components/vendor/General';
import Delivery from '@/components/vendor/Delivery';
import Products from '@/components/vendor/Products';
import Orders from '@/components/vendor/Orders';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    General,
    Delivery,
    Products,
    Orders,
  },
  data() {
    return {
      link: this.$route.params.tab,
      menuItems: [
        { link: 'general', title: 'General' },
        { link: 'delivery', title: 'Delivery' },
        { link: 'products', title: 'Products' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getVendorItem');
    this.$store.dispatch('getVendorItemSchedule');
    this.$store.dispatch('getSettingsVat', '?all=true');
  },
  methods: {
    clickMenu(l) {
      if (this.$route.fullPath != `/gift-vendors/${this.$route.params.vendorId}/${l}`) {
        this.$router.push(`/gift-vendors/${this.$route.params.vendorId}/${l}`);
      }
      this.link = l;
      this.backBooking();
    },
    backDetail() {
      this.backBooking();
      this.$nextTick(() => {
        this.link = 'general';
        this.$router.push(`/gift-vendors/${this.$route.params.vendorId}/general`);
      });
    },
    backBooking() {
      this.$store.dispatch('setLink', '');
    },
  },
  watch: {
    number() {
      this.link = this.$route.params.tab;
    },
    route(val) {
      this.link = this.$route.params.tab;
      if (Object.keys(val).length) {
        this.$store.dispatch('setLink', Object.values(val)[0]);
      } else {
        this.$store.dispatch('setLink', '');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.vendor;
    },
    number() {
      return this.$store.getters.link;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    route() {
      return this.$route.query;
    },
  },
  destroyed() {
    this.$store.dispatch('setVendorItem');
    this.$store.dispatch('setVendorItemSchedule');
    this.$store.dispatch('setVendorProductsList');
    this.$store.dispatch('setProductOrdersList');
    this.$store.dispatch('setLink', '');
  },
};
</script>

<style lang="scss">
.vendor-item {
  table thead tr th {
    background: initial !important;
  }
  table tbody tr:hover {
    background: initial !important;
  }
}
</style>
