<template>
  <div>
    <Details :dataItem="modalData" v-if="link" />
    <div v-else class="vendor-orders d-flex flex-column">
      <div class="px-sm-6 pt-sm-6 pt-4 pb-sm-4 pb-2 px-3 d-flex align-center">
        <div>
          <div class="text-h6 font-weight-bold">Orders</div>
          <div class="text--text">{{ data.total }} {{ data.total > 1 ? 'orders' : 'order' }}</div>
        </div>
        <v-spacer></v-spacer>
        <v-card flat tile class="ms-sm-3 d-flex align-center flex-wrap width100" max-width="400">
          <v-text-field
            class="field46 mt-sm-0 mt-2"
            v-model="search"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            hide-details
            outlined
            dense
            required
            color="primary"
            height="46"
            @change="(page = 1), getData()"
          ></v-text-field>
        </v-card>
      </div>
      <v-divider></v-divider>
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="headers"
        :items="data.result"
        item-key="id"
        :items-per-page="-1"
        class="d-md-block d-none"
      >
        <template v-slot:header.status>
          Status
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="status != 'all' ? 'primary white--text' : ''">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :class="status == item.id ? 'primary--text font-weight-bold' : ''"
                class="text-body-2"
                link
                v-for="item in itemsStatus"
                :key="item.id"
                @click="(status = item.id), getData()"
                style="min-height: 36px"
              >
                {{ item.title }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:header.submitted_at>
          Submitted date
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="sort != 'all' ? 'primary white--text' : ''">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :class="sort == item.id ? 'primary--text font-weight-bold' : ''"
                class="text-body-2"
                link
                v-for="item in itemsSort"
                :key="item.id"
                @click="(sort = item.id), getData()"
                style="min-height: 36px"
              >
                {{ item.title }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-if="!data.pages" v-slot:no-data>
          <v-card tile flat color="transparent" class="d-flex justify-center align-center">
            <div>
              <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
              <div class="text-center text--text mt-4">Sorry, but nothing found</div>
            </div>
          </v-card>
        </template>
        <template v-else v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id" @click="openItem(item)" class="link">
              <td>{{ item.number }}</td>
              <td>{{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}</td>
              <td>SAR {{ item.total / 100 }}</td>
              <td class="text-capitalize">{{ item.status }}</td>
              <td>{{ new Date(item.submitted_at).toLocaleString('en-GB').split('/').join('.') }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div class="d-block d-md-none">
        <v-card v-if="!data.pages" tile flat color="transparent" class="d-flex justify-center align-center">
          <div>
            <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
            <div class="text-center text--text mt-4">Sorry, but nothing found</div>
          </div>
        </v-card>
        <div v-else v-for="item in data.result" :key="item.id" class="px-4 pt-2" @click="openItem(item)">
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Number</div>
            <div>{{ item.number }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Customer</div>
            <div>{{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Total</div>
            <div class="text-capitalize">SAR {{ item.total / 100 }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Status</div>
            <div class="text-capitalize">{{ item.status }}</div>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Submitted date</div>
            <div>{{ new Date(item.submitted_at).toLocaleString('en-GB').split('/').join('.') }}</div>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
      <v-spacer></v-spacer>
      <v-divider v-if="data.pages"></v-divider>
      <div class="py-2 px-sm-5 px-3 d-flex align-center justify-center flex-wrap" v-if="data.pages">
        <v-spacer></v-spacer>
        <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
        <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Details from '@/components/vendor/OrderDetail';

export default {
  components: {
    Details,
  },
  data() {
    return {
      modalData: {},
      status: 'all',
      sort: 'all',
      search: '',
      page: 1,
      headers: [
        { text: 'Number', value: 'number', sortable: false },
        { text: 'Customer', value: 'receiver', sortable: false },
        { text: 'Total', value: 'total', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Submitted date', value: 'submitted_at', sortable: false },
      ],
      itemsStatus: [
        { id: 'all', title: 'All' },
        { id: 'paid', title: 'Paid' },
        { id: 'delivered', title: 'Delivered' },
        { id: 'canceled', title: 'Canceled' },
      ],
      itemsSort: [
        { id: 'all', title: 'All' },
        { id: 'submitted_at_asc', title: 'Submitted at ASC' },
        { id: 'submitted_at_desc', title: 'Submitted at DESC' },
        { id: 'delivery_date_asc', title: 'Delivery date ASC' },
        { id: 'delivery_date_desc', title: 'Delivery date DESC' },
      ],
      statusError: null,
    };
  },
  mounted() {
    this.getData();
    if (this.$route.query.order) {
      this.modalData.number = this.$route.query.order;
      this.$store.dispatch('setLink', this.modalData.number);
    }
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openItem(i) {
      this.modalData = i;
      this.$store.dispatch('setLink', i.number);
      this.$router.push(`/gift-vendors/${this.$route.params.vendorId}/orders?order=${i.number}`);
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      this.statusError = null;
      await this.$store
        .dispatch('getProductOrdersList', {
          search: this.search,
          status: this.status,
          sort: this.sort,
          page: this.page,
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.productOrdersList;
    },
    item() {
      return this.$store.getters.productOrder;
    },
    link() {
      return this.$store.getters.link;
    },
  },
};
</script>

<style lang="scss">
.vendor-orders {
  min-height: calc(100vh - 172px);
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
}
</style>
