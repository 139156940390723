<template>
  <div class="vendor-general">
    <div class="pl-lg-9 px-sm-7 pt-sm-7 pt-3 px-3 item-content">
      <div class="text-h6 font-weight-bold">Vendor general details</div>
      <div class="text--text">Information about Vendor will be used for delivery details</div>
      <div class="my-5 d-flex align-center flex-wrap">
        <div class="font-weight-bold">Status</div>
        <v-btn-toggle class="group-btn ms-5" v-model="data.status" @change="updateStatus">
          <v-btn class="text-body-2" value="listed" outlined height="36">Listed</v-btn>
          <v-btn class="text-body-2" value="unlisted" outlined height="36">Unlisted</v-btn>
        </v-btn-toggle>
      </div>
      <div class="font-weight-bold mb-3">Order prefix</div>
      <v-text-field
        class="field46 prefix-field mt-n2"
        v-model="data.prefix"
        placeholder="Prefix"
        :error-messages="orderErrors"
        outlined
        dense
        required
        color="primary"
        height="46"
        :readonly="(role == 'admin' || role == 'manager') ? false : true"
      ></v-text-field>
      <div class="font-weight-bold mb-2 mt-n2">Title</div>
      <div class="d-flex flex-md-row flex-column multi-direct">
        <v-text-field
          class="field46 width100 me-md-3"
          v-model="data.title_en"
          placeholder="Title"
          :error-messages="titleEnErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="(role == 'admin' || role == 'manager') ? false : true"
        ></v-text-field>
        <v-text-field
          class="field46 width100 mt-md-0 mt-n2 ms-md-3"
          v-model="data.title_ar"
          placeholder="عنوان"
          :error-messages="titleArErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
          :readonly="(role == 'admin' || role == 'manager') ? false : true"
        ></v-text-field>
      </div>
      <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
        <v-textarea
          class="width100 me-md-3"
          v-model="data.description_en"
          placeholder="Description"
          :error-messages="desEnErrors"
          auto-grow
          rows="4"
          row-height="30"
          outlined
          dense
          required
          color="primary"
          :readonly="(role == 'admin' || role == 'manager') ? false : true"
        ></v-textarea>
        <v-textarea
          class="width100 mt-md-0 mt-n2 ms-md-3"
          v-model="data.description_ar"
          placeholder="وصف كامل"
          :error-messages="desArErrors"
          auto-grow
          rows="4"
          row-height="30"
          outlined
          dense
          required
          color="primary"
          :readonly="(role == 'admin' || role == 'manager') ? false : true"
        ></v-textarea>
      </div>
      <div class="font-weight-bold mb-3 mt-n2">Email</div>
      <v-text-field
        class="field46 mt-n2"
        v-model="data.email"
        placeholder="Email"
        :error-messages="emailErrors"
        outlined
        dense
        required
        color="primary"
        height="46"
        :readonly="(role == 'admin' || role == 'manager') ? false : true"
      ></v-text-field>
      <div class="font-weight-bold mb-3 mt-n2">Upload logo</div>
      <v-card
        @click="role == 'admin' || role == 'manager' ? onFileChange($event) : ''"
        flat
        class="d-flex align-center justify-center card-img"
        width="200"
        height="200"
      >
        <div class="primary--text text-center" v-if="!data.picture">
          <v-img max-width="44" class="mx-auto primary-filter" src="@/assets/icon/upload.svg"></v-img>
          <div class="font-weight-bold">Upload logo</div>
          <div class="f13">200x200</div>
        </div>
        <img width="198" height="198" v-else :src="data.picture" />
        <input v-if="role == 'admin' || role == 'manager'" type="file" accept="image/*" @change="onFileChange($event)" />
      </v-card>
    </div>
    <v-divider class="mt-5" v-if="role == 'admin' || role == 'manager'"></v-divider>
    <div class="d-flex align-center pl-lg-9 px-sm-7 py-3 px-3" v-if="role == 'admin' || role == 'manager'">
      <v-btn @click="modal = true" class="rounded" outlined depressed height="30" width="116">
        <span class="error--text">Remove</span>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="updateItem" class="rounded" depressed color="primary" height="30" width="116">Save</v-btn>
    </div>
    <v-dialog v-model="modal" width="480">
      <v-card class="pa-4">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to remove product vendor {{ data.title_en }}?</div>
        <div class="text-end">
          <v-btn @click="modal = false" outlined class="rounded" color="gray lighten-1" width="106" height="30">
            <span class="text-body-2 black--text">Cancel</span>
          </v-btn>
          <v-btn @click="removeVeddor" depressed class="error rounded ms-3" width="106" height="30">
            <span class="text-body-2">Remove</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      file: '',
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Vendor info saved',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Vendor info updated',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'updatePicture') {
        this.$notify({
          title: 'Done!',
          message: 'Vendor info picture updated',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Vendor removed',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async onFileChange(e) {
      this.file = e.target.files;
      if (!this.file.length) {
        return;
      } else {
        this.error = [];
        const formData = new FormData();
        formData.append('picture', this.file[0]);
        await this.$store
          .dispatch('setPhotoVendor', { data: formData })
          .then(() => {
            this.notifi('updatePicture');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    async updateStatus() {
      const data = new Object();
      data.status = this.data.status;
      await this.$store.dispatch('statusVendorItem', { data: data }).then(() => {
        this.notifi('update');
      });
    },
    async removeVeddor() {
      await this.$store.dispatch('deleteVendorItem').then(() => {
        this.notifi('remove');
      });
    },
    async updateItem() {
      this.error = [];
      const data = new Object();
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      data.order_prefix = this.data.prefix;
      data.email = this.data.email;
      this.data.delivery_vat_option ? (data.delivery_vat_option = this.data.delivery_vat_option.id) : '';
      data.delivery_vat_included = this.data.delivery_vat_included;
      await this.$store
        .dispatch('updateVendorItem', { data: data })
        .then(() => {
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.vendor;
    },
    role() {
      return this.$store.getters.profile.role;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    pictureErrors() {
      const errors = [];
      this.error.find((item) => item == 'picture__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'picture__invalid') && errors.push('Selected picture is not valid');
      return errors;
    },
    orderErrors() {
      const errors = [];
      this.error.find((item) => item == 'order_prefix__required') && errors.push('Please enter order prefix');
      this.error.find((item) => item == 'order_prefix__invalid') && errors.push('Provided order prefix is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter email address');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((item) => item == 'email__exists') &&
        errors.push('Provided email address is already registered. Please use another one');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
  },
};
</script>

<style lang="scss">
#app .vendor-general {
  .prefix-field {
    .v-input__slot {
      max-width: 116px !important;
    }
  }
  .v-input {
    max-width: 371px;
  }
  .card-img {
    background: var(--v-primary-lighten3);
    border: 1px dashed var(--v-primary-base);
    img {
      object-fit: contain;
    }
  }
  @media screen and (max-width: 700px) {
    .v-input {
      max-width: initial !important;
    }
    .group-btn {
      display: grid;
      button {
        border-width: thin !important;
        border-radius: 4px !important;
      }
    }
  }
}
</style>
